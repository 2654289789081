
import {defineComponent, onMounted, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import PageModel4 from '@/components/page-model4/index.vue';
import { query_alarm_type_list } from '@/api/alarm-model';
import { debounce } from 'lodash-es';
export default defineComponent({
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const search = reactive({
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        record: {},
        is_active: true,
        alarm_type_id: null,
      },
    });
    const tableHeight = reactive({ y: window.innerHeight - 110 });
    const onresize: any = debounce(() => {
        tableHeight.y = window.innerHeight - 110;
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });
    query_alarm_type_list({
      org_id: current_org?.id,
      is_active: true,
      pageSize: 2000,
    }).then((record: any) => {
      if (record.length > 0) {
        fangan_list.search.requestParams.alarm_type_id = record[0].id;
      }
    });

    const scgyfangan = reactive({
      title: '报警类型',
      column_flag: 'alarm-alarmtype',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'alarm-alarmtype',
      height: tableHeight.y,
      mode: 'radio',
    });

    const fangan_list = reactive({
      title: '报警事件',
      column_flag: 'alarm-alarmevent',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'alarm-alarmevent',
      height: tableHeight.y,
      mode: 'null',
    });

    const getFangan = (record: any) => {
      fangan_list.search.requestParams.record = { ...record };
      fangan_list.search.requestParams.alarm_type_id = record.id;
    };
    return {
      tableHeight,
      scgyfangan,
      fangan_list,
      getFangan,
    };
  },
  components: {
    PageModel4,
  },
});
