import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_model_4 = _resolveComponent("page-model-4")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_page_container3 = _resolveComponent("page-container3")!

  return (_openBlock(), _createBlock(_component_page_container3, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, {
        gutter: 5,
        type: "flex"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, _normalizeProps(_guardReactiveProps({ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 })), {
            default: _withCtx(() => [
              _createVNode(_component_page_model_4, {
                modalType: _ctx.scgyfangan.modalType,
                search: _ctx.scgyfangan.search,
                title: _ctx.scgyfangan.title,
                column_flag: _ctx.scgyfangan.column_flag,
                height2: _ctx.tableHeight.y,
                mode: _ctx.scgyfangan.mode,
                is_search: true,
                onOk: _ctx.getFangan
              }, null, 8, ["modalType", "search", "title", "column_flag", "height2", "mode", "onOk"])
            ]),
            _: 1
          }, 16),
          _createVNode(_component_a_col, _normalizeProps(_guardReactiveProps({ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 })), {
            default: _withCtx(() => [
              _createVNode(_component_page_model_4, {
                modalType: _ctx.fangan_list.modalType,
                search: _ctx.fangan_list.search,
                title: _ctx.fangan_list.title,
                column_flag: _ctx.fangan_list.column_flag,
                height2: _ctx.tableHeight.y,
                mode: _ctx.fangan_list.mode,
                is_search: true
              }, null, 8, ["modalType", "search", "title", "column_flag", "height2", "mode"])
            ]),
            _: 1
          }, 16)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}